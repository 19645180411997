import { useLayoutEffect } from 'react';

interface ParityCallbacks {
  oddCallback?: () => void;
  evenCallback?: () => void;
}

export const useIdParityEffect = (id: string | undefined, { oddCallback, evenCallback }: ParityCallbacks) => {
  useLayoutEffect(() => {
    if (!id) return;

    const lastDigit = id.toString().slice(-1);
    if (!lastDigit) return;

    const isOdd = parseInt(lastDigit, 16) % 2 !== 0;

    if (isOdd) oddCallback?.();
    else evenCallback?.();
  }, [id, oddCallback, evenCallback]);
};
