import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import Button from 'components/Button';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import MonthYearPicker from 'components/MonthYearPicker';
import NumberInput from 'components/NumberInput';

import { CurrentFlow } from 'enums/CurrentFlow';
import { YourIncomeResult } from 'enums/FlowNextResults';
import { YourIncomeVariable } from 'enums/LoanFormVariables';
import { getPartnerFromEmployerName, PARTNER_DATA, PartnerType } from 'enums/PartnerName';

import { PreQualificationData, setPartnerName } from 'handlers/preQualificationData';
import { setYourIncomeData } from 'handlers/yourIncome';
import { PayFrequencyOptionValue } from 'handlers/yourTotalIncome';

import { FlowComponentType } from 'routes/types';

import { getApplicationStep } from 'selectors/getApplicationStep';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getYourIncome } from 'selectors/yourIncome';

import { formatMonthYear, getYearsFromNow } from 'utils/dateUtils';
import { getMessageForRequiredFields } from 'utils/errors';
import { ConversionType, trackConversion } from 'utils/analytics';

import {
  ANNUAL_INCOME_MAX_LENGTH,
  PAY_FREQUENCY_OPTIONS,
} from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';

import styles from './YourIncome.module.scss';

enum YourIncomeInputLabel {
  CreditScoreRange = 'Approximate credit score',
  GrossAnnualIncome = 'Gross annual income',
  StartOfEmployment = 'Start date of primary employer',
  EmployerName = 'Primary employer name',
  JobTitle = 'Job title',
  PayFrequency = 'Pay frequency',
  NotCurrentlyEmployed = 'Not currently employed',
}

export enum YourIncomeVariant {
  Default,
  CardWaitlist,
  CardCrossSell,
}

const YourIncome = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const state = useLocation().state as { variant?: YourIncomeVariant };
  const variant = state?.variant ?? YourIncomeVariant.Default;
  const { currentFlow } = useSelector(getApplicationStep);
  const { partnerId: partnerName, benefit } = useSelector(getPreQualificationData);
  const defaultValues = useSelector(getYourIncome);
  const minDate = getYearsFromNow(-50);
  const maxDate = getYearsFromNow(0);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      ...(partnerName &&
        PARTNER_DATA[partnerName].type === PartnerType.Employer && {
          employer_name: PARTNER_DATA[partnerName].fullName,
        }),
    },
  });

  const watcher = watch();

  const initialPartner = benefit && partnerName ? PARTNER_DATA[partnerName] : null;
  const benefitEligibleTitles = initialPartner?.benefitEligibleTitles ?? [];

  const shouldCollectJobTitle = benefitEligibleTitles.length > 0;

  useEffect(() => {
    if (initialPartner && !!initialPartner.paymentFrequency) {
      setValue(YourIncomeVariable.PayFrequency, initialPartner.paymentFrequency);
    }
  }, [initialPartner]);

  const checkForPartner = (employerName: string) => {
    const partnerId = getPartnerFromEmployerName(employerName);
    if (partnerId) {
      const preQualificationData: PreQualificationData = {
        partnerId,
      };

      dispatch(setPartnerName(preQualificationData));
    }
  };

  const handleContinue = () => {
    dispatch(setYourIncomeData(watcher));
    checkForPartner(watcher[YourIncomeVariable.EmployerName] as string);
    if (variant === YourIncomeVariant.CardWaitlist) {
      const creditScoreRange = watcher[YourIncomeVariable.CreditScoreRange];
      const startOfEmployment = watcher[YourIncomeVariable.StartOfEmployment];

      const startDate = new Date(startOfEmployment ?? '');
      const now = new Date();
      const tenureMonths = (now.getFullYear() - startDate.getFullYear()) * 12 + now.getMonth() - startDate.getMonth();

      const mayQualifyForLoan =
        // (Number(creditScoreRange) >= 580 && tenureMonths >= 12) ||
        Number(creditScoreRange) >= 650 && tenureMonths >= 6;

      analytics.track('Income Data Collected', {
        creditScoreRange,
        totalAnnualIncome: watcher[YourIncomeVariable.TotalAnnualIncome],
        employerName: watcher[YourIncomeVariable.EmployerName],
        startOfEmployment,
        notEmployed: watcher[YourIncomeVariable.NotEmployed],
        tenureMonths,
        mayQualifyForLoan,
      });

      if (mayQualifyForLoan) {
        trackConversion(ConversionType.CardMayQualifyForLoan);
      }

      handleNext(YourIncomeResult.CardWaitlist);
    } else {
      handleNext();
    }
  };

  const validateEmploymentFields = (errorMessage: string) => (value: any) =>
    watch()[YourIncomeVariable.NotEmployed] || (value !== null && value !== undefined && value !== '')
      ? true
      : errorMessage;

  const validateStartOfEmployment = (value: string) => {
    if (watch(YourIncomeVariable.NotEmployed)) return true;
    if (!value) return getMessageForRequiredFields(YourIncomeInputLabel.StartOfEmployment);

    const parsedDate = new Date(value);

    if (parsedDate < minDate) return `Date must be after ${formatMonthYear(minDate)}`;
    if (parsedDate > maxDate) return `Date must be before or equal to ${formatMonthYear(maxDate)}`;
  };

  useEffect(() => {
    register(YourIncomeVariable.CreditScoreRange, {
      min: {
        message: `${YourIncomeInputLabel.CreditScoreRange} should be at least 350`,
        value: 350,
      },
      max: {
        message: `${YourIncomeInputLabel.CreditScoreRange} should be at most 850`,
        value: 850,
      },
    });
    register(YourIncomeVariable.TotalAnnualIncome, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.GrossAnnualIncome),
    });
    register(YourIncomeVariable.StartOfEmployment, {
      validate: validateStartOfEmployment,
    });
    register(YourIncomeVariable.EmployerName, {
      validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.EmployerName)),
    });
    if (variant !== YourIncomeVariant.CardWaitlist) {
      register(YourIncomeVariable.PayFrequency, {
        validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.PayFrequency)),
      });
    }
    register(YourIncomeVariable.NotEmployed);
    if (shouldCollectJobTitle) {
      register(YourIncomeVariable.JobTitle, {
        validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.JobTitle)),
      });
    }
  }, [register]);

  let title = '';
  let subtitle: string | JSX.Element = <></>;

  if (variant === YourIncomeVariant.CardWaitlist) {
    title = 'Help Improve Plannery';
    subtitle = (
      <>
        <p>Please enter some more details about you so we can better serve the nursing community.</p>
        <p>We never share or sell your data.</p>
      </>
    );
  } else if (currentFlow === CurrentFlow.Card || currentFlow === CurrentFlow.DebtConsolidation) {
    title = 'What’s your income?';
    subtitle = 'This information is needed to put together your offer.';
  } else {
    title = 'What’s your income?';
    subtitle = 'This will help us calculate your results.';
  }

  return (
    <>
      <FormNavigation
        {...navigationInfo}
        {...(variant === YourIncomeVariant.CardCrossSell && { step: 2, stepCount: 2 })}
      />
      <FormContainer title={title} subtitle={subtitle}>
        {variant === YourIncomeVariant.CardWaitlist && (
          <NumberInput
            label={YourIncomeInputLabel.CreditScoreRange}
            onChange={(option) => {
              setValue(YourIncomeVariable.CreditScoreRange, option.target.value);
              trigger(YourIncomeVariable.CreditScoreRange);
            }}
            placeholder="Enter a number"
            className={styles.formInput}
            name={YourIncomeVariable.CreditScoreRange}
            errorMessage={errors[YourIncomeVariable.CreditScoreRange]?.message}
            value={watcher[YourIncomeVariable.CreditScoreRange] ?? undefined}
          />
        )}
        <NumberInput
          label={YourIncomeInputLabel.GrossAnnualIncome}
          inputMode="numeric"
          prefix="$"
          placeholder="$0"
          errorMessage={errors[YourIncomeVariable.TotalAnnualIncome]?.message}
          thousandSeparator
          name={YourIncomeVariable.TotalAnnualIncome}
          onChange={(event) => {
            setValue(YourIncomeVariable.TotalAnnualIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
            trigger(YourIncomeVariable.TotalAnnualIncome);
          }}
          value={
            watcher[YourIncomeVariable.TotalAnnualIncome]
              ? `${watcher[YourIncomeVariable.TotalAnnualIncome]}`
              : undefined
          }
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          autoFocus={variant !== YourIncomeVariant.CardWaitlist}
        />
        {variant !== YourIncomeVariant.CardWaitlist && (
          <div className={styles.note}>
            Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
            considered as a basis for repaying this obligation.
          </div>
        )}
        <Input
          label={YourIncomeInputLabel.EmployerName}
          className={styles.formInput}
          onChange={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value);
            trigger(YourIncomeVariable.EmployerName);
          }}
          onBlur={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value.trim());
            trigger(YourIncomeVariable.EmployerName);
            checkForPartner(event.target.value.trim());
          }}
          disabled={watcher[YourIncomeVariable.NotEmployed]}
          placeholder="Employer name"
          value={watcher[YourIncomeVariable.EmployerName]}
          errorMessage={errors[YourIncomeVariable.EmployerName]?.message}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
        />
        <CheckboxSmall
          label={YourIncomeInputLabel.NotCurrentlyEmployed}
          name={YourIncomeVariable.NotEmployed}
          onChange={(event) => {
            setValue(YourIncomeVariable.NotEmployed, event.target.checked);
            // Reset the rest of the fields
            setValue(YourIncomeVariable.EmployerName, '');
            setValue(
              YourIncomeVariable.PayFrequency,
              event.target.checked
                ? PayFrequencyOptionValue.Monthly
                : initialPartner?.paymentFrequency ?? PayFrequencyOptionValue.BiWeekly,
            );
            setValue(YourIncomeVariable.StartOfEmployment, '');
            trigger([
              YourIncomeVariable.NotEmployed,
              YourIncomeVariable.EmployerName,
              YourIncomeVariable.PayFrequency,
              YourIncomeVariable.StartOfEmployment,
              ...(shouldCollectJobTitle ? [YourIncomeVariable.JobTitle] : []),
            ]);
          }}
          checked={watcher[YourIncomeVariable.NotEmployed]}
        />
        {watcher[YourIncomeVariable.EmployerName]!.length > 0 && variant !== YourIncomeVariant.CardWaitlist && (
          <InputSelect
            label={YourIncomeInputLabel.PayFrequency}
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              setValue(YourIncomeVariable.PayFrequency, option.value);
              trigger(YourIncomeVariable.PayFrequency);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={YourIncomeVariable.PayFrequency}
            value={watcher[YourIncomeVariable.PayFrequency]}
            errorMessage={errors[YourIncomeVariable.PayFrequency]?.message}
          />
        )}
        {watcher[YourIncomeVariable.EmployerName]!.length > 0 && (
          <MonthYearPicker
            name={YourIncomeVariable.StartOfEmployment}
            minDate={minDate}
            maxDate={maxDate}
            value={watcher[YourIncomeVariable.StartOfEmployment] ?? undefined}
            label={YourIncomeInputLabel.StartOfEmployment}
            onChange={(date) => {
              setValue(YourIncomeVariable.StartOfEmployment, date);
              trigger(YourIncomeVariable.StartOfEmployment);
            }}
            errorMessage={errors[YourIncomeVariable.StartOfEmployment]?.message}
          />
        )}
        {shouldCollectJobTitle && !watcher[YourIncomeVariable.NotEmployed] && (
          <InputSelect
            onChange={(option) => {
              setValue(YourIncomeVariable.JobTitle, option.value);
              trigger(YourIncomeVariable.JobTitle);
            }}
            className={styles.inputContainer}
            placeholder="Select"
            value={watcher[YourIncomeVariable.JobTitle]}
            label={YourIncomeInputLabel.JobTitle}
            onBlur={(event) => {
              setValue(event.target.name as YourIncomeVariable, event.target.value);
              trigger(event.target.name as YourIncomeVariable);
            }}
            options={[
              ...benefitEligibleTitles.map((item) => ({ label: item, value: item })),
              { label: 'Other', value: 'Other' },
            ]}
          />
        )}

        {variant === YourIncomeVariant.CardCrossSell && (
          <span className={styles.yourIncomeDisclaimer}>
            By clicking Apply, I hereby consent to the Plannery{' '}
            <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
              E-Sign Agreement
            </a>
            ,{' '}
            <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            , and{' '}
            <a href="https://www.planneryapp.com/communication-policy" target="_blank" rel="noreferrer">
              Communication Policy
            </a>{' '}
            and the{' '}
            <a
              href="https://assets.planneryapp.com/finwise/Privacy%20Notice%20FWB%2001.2025.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Finwise Bank Privacy Policy
            </a>
            , and I am providing written consent under the FCRA for Plannery to obtain consumer report information from
            my credit profile.
          </span>
        )}
        <Button className={styles.button} disabled={!isValid} onClick={handleContinue}>
          {variant === YourIncomeVariant.CardWaitlist ? 'Next' : 'Apply'}
        </Button>
      </FormContainer>
    </>
  );
};

export default YourIncome;
