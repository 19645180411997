import { useDispatch, useSelector } from 'react-redux';
import { getApplicationApr, getApplicationData } from 'thunks';
import { ConversionType, trackConversion, trackConversionLead } from 'utils/analytics';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { RoutePath } from 'enums/Routes';
import { ApplyingResult } from 'enums/FlowNextResults';
import { FlowComponentType } from 'routes/types';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { LoanOfferResponse } from 'handlers/loanOffer';
import RequestError from 'errors/RequestError';
import { ErrorType } from 'components/Error/ErrorType';

import { useNavigate } from './useNavigate';
import useMethodAuth from './useMethodAuth';
import useDispatchWithUnwrap from './useDispatchWithUnwrap';

interface UseLoanOfferProcessorProps {
  handleNext: FlowComponentType['handleNext'];
}

const useLoanOfferProcessor = ({ handleNext }: UseLoanOfferProcessorProps) => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const { processMethodErrorAndRedirect } = useMethodAuth();

  const { isLoading } = useSelector(getLoanOffer);
  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const processLoanOfferResponse = async (loanOfferResponse: LoanOfferResponse) => {
    // Only send to offer status if already applied
    if (loanOfferResponse.data.application_status === ApplicationStatusName.AlreadyApplied) {
      navigate(RoutePath.OfferStatus);
      return;
    }

    if (loanOfferResponse.data.application_status === ApplicationStatusName.OfferAvailable) {
      // Getting the APR from the LMS takes up to 3 seconds, so we pre-fetch it after the loan offer is generated
      dispatch(getApplicationApr(loanOfferResponse.data.application_id!));
    }

    if (loanOfferResponse.data.borrower_id) {
      analytics.identify(loanOfferResponse.data.borrower_id);
      (window as any).nid('setUserId', loanOfferResponse.data.application_id);
    }

    const isQualified = loanOfferResponse.data.credit_passed;

    if (loanOfferResponse.data.application_id) {
      await dispatchWithUnwrap(getApplicationData(loanOfferResponse.data.application_id!));
    }
    if (isQualified) {
      trackConversionLead({
        email,
        firstName,
        lastName,
        phoneNumber,
      });
    }
    trackConversion(ConversionType.FinancialCheckupApplied);

    handleNext(ApplyingResult.Done);
  };

  const processLoanOffer = async (loanOfferFn: () => Promise<LoanOfferResponse | undefined>) => {
    if (processMethodErrorAndRedirect(handleNext)) return;

    try {
      const loanOfferResponse = await loanOfferFn();
      if (!loanOfferResponse) return;
      await processLoanOfferResponse(loanOfferResponse);
      return loanOfferResponse;
    } catch (error) {
      if ((error as RequestError<unknown>).responseStatus === 401) {
        handleNext(ApplyingResult.SessionExpired);
      } else {
        navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
      }
    }
  };

  return {
    isLoading,
    processLoanOffer,
    processLoanOfferResponse,
  };
};

export default useLoanOfferProcessor;
