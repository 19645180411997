import YourEmail, { YourEmailState } from 'components/FinancialCheckup/Email/YourEmail';
import VerifyEmail from 'components/VerifyEmail';
import YourIncome, { YourIncomeVariant } from 'components/FinancialCheckup/Income/YourIncome';
import MethodAuth from 'components/FinancialCheckup/MethodAuth';
import BirthDate from 'components/LoanForm/BirthDate';
import YourAddress from 'components/LoanForm/YourAddress';
import YourSSN4 from 'components/LoanForm/YourSSN/YourSSN';
import VerifyPhoneNumber from 'components/VerifyPhoneNumber';
import { RoutePath } from 'enums/Routes';
import YourPhoneNumber, { YourPhoneNumberState } from 'components/FinancialCheckup/PhoneNumber/YourPhoneNumber';
import ConfirmYourCredentials from 'components/LoanForm/ConfirmYourCredentials';
import { VerifyEmailResult, VerifyCodeResult, YourIncomeResult } from 'enums/FlowNextResults';

import { CustomRouterType, NextFunction, RouterType } from './types';

export const getApplyStageRoutes = (
  yourNameRouter: CustomRouterType,
  analyzingComponent: CustomRouterType['component'],
  next: NextFunction,
): RouterType => ({
  [RoutePath.YourName]: yourNameRouter,
  [RoutePath.ConfirmYourCredentials]: {
    navigationInfo: { showBackLink: true, title: 'Your Professional Credentials', step: 1, stepCount: 5 },
    component: ConfirmYourCredentials,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourPhoneNumber);
    },
  },
  [RoutePath.YourPhoneNumber]: {
    navigationInfo: { showBackLink: true, title: 'Sign Up', step: 2, stepCount: 5 },
    component: YourPhoneNumber,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerifyPhoneNumber);
    },
  },
  [RoutePath.VerifyPhoneNumber]: {
    navigationInfo: { showBackLink: true, title: 'Sign Up', step: 3, stepCount: 5 },
    component: VerifyPhoneNumber,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case VerifyCodeResult.DifferentPhoneNumberSession:
          navigate(RoutePath.YourPhoneNumber, {
            state: { isDifferentPhoneNumberSession: true } as YourPhoneNumberState,
          });
          break;
        default:
          navigate(RoutePath.YourEmail);
          break;
      }
    },
  },
  [RoutePath.YourEmail]: {
    navigationInfo: { showBackLink: true, title: 'Sign Up', step: 4, stepCount: 5 },
    component: YourEmail,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerifyEmail);
    },
  },
  [RoutePath.VerifyEmail]: {
    navigationInfo: { showBackLink: true, title: 'Sign Up', step: 4, stepCount: 5 },
    component: VerifyEmail,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case VerifyCodeResult.DifferentEmailSession:
          navigate(RoutePath.YourEmail, { state: { isDifferentEmailSession: true } as YourEmailState });
          break;
        case VerifyEmailResult.CardFlow:
          navigate(RoutePath.YourIncome, { state: { variant: YourIncomeVariant.CardWaitlist } });
          break;
        default:
          navigate(RoutePath.YourIncome, { state: { variant: YourIncomeVariant.Default } });
          break;
      }
    },
  },
  [RoutePath.YourIncome]: {
    navigationInfo: { showBackLink: true, title: 'Sign Up', step: 5, stepCount: 5 },
    component: YourIncome,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case YourIncomeResult.CardWaitlist:
          navigate(RoutePath.BuildCreditAvoidDebtSaveMoney, {
            state: {
              fromApplyStage: true,
            },
          });
          break;
        default:
          navigate(RoutePath.Analyzing);
          break;
      }
    },
  },
  [RoutePath.MethodAuth]: {
    navigationInfo: { showBackLink: true, title: 'Connect', step: 4, stepCount: 4 },
    component: MethodAuth,
    handleNext: () => () => {},
  },

  [RoutePath.YourBirthDate]: {
    navigationInfo: { title: 'Apply', showBackLink: false },
    component: BirthDate,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourSSN4);
    },
  },
  [RoutePath.YourSSN4]: {
    navigationInfo: { title: 'Apply' },
    component: YourSSN4,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourAddress);
    },
  },
  [RoutePath.YourAddress]: {
    navigationInfo: { title: 'Apply' },
    component: YourAddress,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Analyzing);
    },
  },
  [RoutePath.Analyzing]: {
    component: analyzingComponent,
    handleNext: next,
  },
});
