import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { FlowComponentType } from 'routes/types';
import { RootState } from 'handlers';
import { setYourBirthDateData } from 'handlers/yourBirthDate';
import { YourBirthDateVariable } from 'enums/LoanFormVariables';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import InputError from 'components/InputError';
import { getMessageForRequiredFields } from 'utils/errors';
import { formatDate, formatMonthYear, formatYearMonthDayToDate, formatDateToInput } from 'utils/dateUtils';
import useScreenSize from 'hooks/useScreenSize';

import styles from './BirthDate.module.scss';

export enum YourBirthDateInputLabel {
  DateOfBirth = 'Date of birth',
}

const BirthDate = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { birth_date: birthDate } = useSelector((state: RootState) => state.yourBirthDate);

  // This prevents Method errors if the calculated date is out of valid range
  const today = new Date();
  const maxBirthDate = new Date(today.getFullYear() - 17, today.getMonth(), today.getDate() - 1);

  const {
    formState: { errors, isValid },
    register,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      birth_date: birthDate ? new Date(birthDate) : null,
    },
  });

  const watcher = watch();

  const isSafariDesktop = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !isMobile;

  const validateBirthDate = (value: string) => {
    const parsedDate = new Date(value);
    if (parsedDate > maxBirthDate) return `Date must be before or equal to ${formatMonthYear(maxBirthDate)}`;

    return true;
  };

  useEffect(() => {
    register(YourBirthDateVariable.DateOfBirth, {
      required: getMessageForRequiredFields(YourBirthDateInputLabel.DateOfBirth),
      validate: validateBirthDate,
    });
  });

  const handleContinue = () => {
    dispatch(
      setYourBirthDateData({
        birth_date: formatDate(watcher.birth_date!),
      }),
    );
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="When were you born?" subtitle="We need this to pull your data.">
        <div>
          <input
            type="date"
            name={YourBirthDateVariable.DateOfBirth}
            max={formatDateToInput(maxBirthDate)}
            value={
              watcher[YourBirthDateVariable.DateOfBirth]
                ? formatDateToInput(watcher[YourBirthDateVariable.DateOfBirth])
                : undefined
            }
            className={clsx(
              styles.input,
              watcher[YourBirthDateVariable.DateOfBirth] && styles.inputFilled,
              !isSafariDesktop && styles.inputColor,
            )}
            onChange={(event) => {
              const date = event.target.value;
              setValue(YourBirthDateVariable.DateOfBirth, formatYearMonthDayToDate(date));
              trigger(YourBirthDateVariable.DateOfBirth);
            }}
            data-placeholder={isMobile && !watch()[YourBirthDateVariable.DateOfBirth] ? 'DD/MM/YYYY' : undefined}
          />
          {!isValid && <InputError errorMessage={errors[YourBirthDateVariable.DateOfBirth]?.message} />}
        </div>
        <Button onClick={handleContinue} disabled={!isValid}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default BirthDate;
