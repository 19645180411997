import React, { useEffect, useState } from 'react';

import Button from 'components/Button';

import { BankAccountInfo } from 'api/PlaidApi';
import { updateApplicationCheckingAccount } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import Loader from 'components/Loader';
import { StepComponent } from 'components/Steps/stepUtils';
import useCurrentFlow from 'hooks/useCurrentFlow';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { CheckingAccountResult } from 'enums/FlowNextResults';

import CheckingAccountRow from './components/CheckingAccountRow';

import styles from './SelectAccount.module.scss';

const SelectAccount = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isMissedPaymentFlow } = useCurrentFlow();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingUserSelection, setIsSavingUserSelection] = useState<boolean>(false);

  const { bankAccounts: checkingAccounts } = useSelector((state: RootState) => state.bankAccount)!;
  const { application, isLoading: isLoadingApplication } = useSelector((state: RootState) => state.applicationData!);

  const [selectedAccount, setSelectedAccount] = useState<string>('');

  const handleAccountChange = (account: BankAccountInfo) => {
    if (account.id === selectedAccount) {
      return;
    }

    setSelectedAccount(account.id);
  };

  const handleContinue = async () => {
    if (selectedAccount) {
      await saveUserSelection();
    }

    handleNext(CheckingAccountResult.Continue);
  };

  const saveUserSelection = async () => {
    setIsSavingUserSelection(true);
    await dispatchWithUnwrap(
      updateApplicationCheckingAccount({
        applicationId: application!.id,
        bankAccountId: selectedAccount,
      }),
    );
  };

  useEffect(() => {
    if (checkingAccounts.length) {
      if (checkingAccounts.length > 0) {
        setIsLoading(false);
        setSelectedAccount(checkingAccounts[0].id);
      }
      // Skip checking account list if accounts are less than 2
      if (checkingAccounts.length < 2) {
        handleContinue();
      }
    }
  }, [checkingAccounts]);

  if (isLoading || isLoadingApplication) {
    return (
      <>
        {isMissedPaymentFlow && <FormNavigation showBackLink={false} title="Missed Payment" />}
        <FormContainer>
          <div className={styles.loaderContainer}>
            <Loader color="#795af7" />
          </div>
        </FormContainer>
      </>
    );
  }

  return (
    <>
      {isMissedPaymentFlow && <FormNavigation title="Bank Account" step={3} stepCount={5} />}
      <FormContainer title="Select the account where you deposit your paycheck">
        <div>
          {checkingAccounts.map((account, index) => {
            const { officialName, mask, balance } = account;

            return (
              <CheckingAccountRow
                key={`${account.mask}-${index}`}
                selected={selectedAccount === account.id}
                onClick={() => handleAccountChange(account)}
                accountName={officialName}
                accountNumber={mask}
                accountBalance={balance}
              />
            );
          })}
        </div>
        <Button onClick={handleContinue} isLoading={isSavingUserSelection} disabled={!selectedAccount}>
          Continue
        </Button>
      </FormContainer>
    </>
  );
};

export default SelectAccount;
