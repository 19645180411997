import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as XSellLoader } from 'images/x-sell-loader.svg';
import { ReactComponent as BankLoading } from 'images/bank-loader.svg';
import { RoutePath } from 'enums/Routes';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getLoanOffer } from 'thunks';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import { useNavigate, useNavigationType } from 'hooks/useNavigate';
import { useQueryParams } from 'hooks/useQueryParam';
import StateContainer from 'components/StateContainer';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { FlowComponentType } from 'routes/types';
import { LoanOfferProgress, LoanOfferStep } from 'api/LoanOfferApi';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';
import { getProfessionGroup } from 'selectors/professionGroup';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getClientTimezone } from 'utils/dateUtils';
import { setStudentLoanApplicationId } from 'handlers/studentLoanData';
import { getApplicationStep } from 'selectors/getApplicationStep';
import useLoanOfferProcessor from 'hooks/useLoanOfferProcessor';
import { getCardData } from 'selectors/getCardData';

import styles from './Analyzing.module.scss';

export const getCheckupProgress = (offerProgress: LoanOfferProgress) => {
  let progress;
  switch (offerProgress.step) {
    case LoanOfferStep.ValidatedInput:
      progress = 20;
      break;
    case LoanOfferStep.GettingTradelines:
      progress = 20;
      break;
    case LoanOfferStep.SyncingTradelines:
      if (offerProgress.initialAccounts && offerProgress.syncingAccounts !== undefined) {
        progress = 80 - (50 * offerProgress.syncingAccounts) / offerProgress.initialAccounts;
      } else {
        progress = 20;
      }
      break;
    case LoanOfferStep.RunningDecisionEngine:
      progress = 75;
      break;
    case LoanOfferStep.GeneratingDebtProfile:
      progress = 80;
      break;
    case LoanOfferStep.CreatingHardOffer:
      progress = 90;
      break;
    default:
      progress = 0;
  }
  return progress;
};

export const getProgressText = (progress: LoanOfferProgress, accountSync: boolean, totalAccounts: number) => {
  let title = "We're analyzing your finances...";
  if (accountSync) {
    if (progress.initialAccounts !== progress.syncingAccounts && progress.syncingAccounts) {
      title = `Synced ${progress.syncingAccounts}/${progress.initialAccounts} accounts`;
    } else if (totalAccounts) {
      title = `We've found ${totalAccounts} accounts`;
    }
  }
  return title;
};

const Analyzing = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const { processLoanOffer } = useLoanOfferProcessor({ handleNext });

  const [progress, setProgress] = useState(0);
  const [accountSync, setAccountSync] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [lastProgress, setLastProgress] = useState<LoanOfferProgress | undefined>(undefined);

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName, credentials } = useSelector(getYourNameData);
  const {
    not_employed: notEmployed,
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
    job_title: jobTitle,
    pay_frequency: payFrequency,
  } = useSelector(getYourIncome);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { entityId } = useSelector(getMethodAuthData);
  const { partnerId: partnerName } = useSelector(getPreQualificationData);
  const { sessionToken } = useSelector(getAuthData);
  const { currentFlow } = useSelector(getApplicationStep);
  const { borrowerCredentials: borrowerCardCredentials } = useSelector(getCardData);

  useEffect(() => {
    if (navigationType === 'POP') {
      navigate(RoutePath.YourFinances);
      return;
    }

    const runLoanOffer = async () => {
      if (!entityId) return;
      setProgress(10);
      const loanOfferResponse = await dispatchWithUnwrap(
        getLoanOffer({
          application: {
            firstName,
            lastName,
            professionGroup,
            email,
            phoneNumber,
            entityId,
            credentials: credentials ?? borrowerCardCredentials,
            totalAnnualIncome: totalAnnualIncome ?? 0,
            resumeLink: `${window.location.href}`,
            partnerId: partnerName,
          },
          notEmployed,
          employment: [
            {
              employerName,
              jobTitle,
              hireDate: startOfEmployment ?? undefined,
              payFrequency,
            },
          ],
          timezone: getClientTimezone(),
          currentFlow,
          utm: getUtmTagsVariables(params),
          sessionToken,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          updateCallback: (data) => {
            setProgress(getCheckupProgress(data));
            if (
              [LoanOfferStep.GettingTradelines, LoanOfferStep.SyncingTradelines].includes(data.step) &&
              data.totalAccounts
            ) {
              setAccountSync(true);
              setTotalAccounts(data.totalAccounts || 0);
            }
            setLastProgress(data);
          },
        }),
      );
      dispatch(setStudentLoanApplicationId(loanOfferResponse.data.application_id!));

      setProgress(100);

      return loanOfferResponse;
    };

    processLoanOffer(runLoanOffer);
  }, [entityId]);

  return (
    <div className={styles.container}>
      <StateContainer
        icon={accountSync ? <BankLoading /> : <XSellLoader />}
        progress={progress}
        title={getProgressText(lastProgress!, accountSync, totalAccounts)}
      />
    </div>
  );
};

export default Analyzing;
