export const healthcareCredentials = [
  // Tier 1
  'CRNA',
  'NP',
  'APRN',
  'CNM',
  'CM',
  'CPM',
  'PA-C',
  'MD',
  'DO',
  'DDS',
  'DMD',
  'DPM',
  'DC',
  'AuD',
  'OB',
  'GYN',
  // Tier 2
  'RN',
  'RT',
  'RTT',
  'PT',
  'DPT',
  'OT',
  'OTD',
  'RRT',
  'CRT',
  'LMT',
  'CTRS',
  // Tier 3
  'RHIT',
  'RHIA',
  'CEP',
  'PharmD',
  'RPh',
  'CMD',
  'OD',
  'DVM',
  'CCC-SLP',
  'CGC',
  'LAc',
  'RDH',
  'CPO',
  'RDN',
  'LD',
  'ATC',
  'CNMT',
  'RDMS',
  'RVT',
  'RDCS',
  'ARMRIT',
  'ARRT',
  'RT(R)',
  'RCIS',
  'RCES',
  'CST',
  'MLS(ASCP)',
  'MLT(ASCP)',
  'CVT',
  'RVT',
  'LVT',
  'EMT',
  'COMT',
  'CPhT',
  'LPT',
  'CPT',
  'NDTR',
  'PCT',
  // Tier 4
  'CSA',
  'COTA',
  'PTA',
  'BC-HIS',
  'NRP',
  'RHIT',
  'CPC',
  'ABOC',
  'NCLEC',
  'CDA',
  'CRCST',
  'CMA',
  'RMA',
  'CPT',
  'RHDS',
  'CHDS',
  'CNA',
  'HHA',
  'PCA',
  'LPN',
  'LVN',
] as const;
