import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/types';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { CREDENTIALS_REGEX } from 'components/LoanForm/YourName/YourName';
import Button from 'components/Button';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import { InputSelectOption } from 'components/InputSelect/InputSelect';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { ConfirmYourCredentialsVariable } from 'enums/LoanFormVariables';

import { CredentialType, setCredentials } from 'handlers/yourName';
import { getYourNameData } from 'selectors/yourName';

import { healthcareCredentials } from './index';

export const CREDENTIALS_REQUIRED_MESSAGE = 'At least one credential (e.g., MD, RN, PA) is required';

enum ConfirmYourCredentialsInputLabel {
  Credential = 'Select your closest credential',
}

const ConfirmYourCredentials = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();
  const { credentials, credential_type: credentialType } = useSelector(getYourNameData);
  const isOtherTypeOfCredential = credentialType === CredentialType.Other;
  const [selectedOther, setSelectedOther] = useState(isOtherTypeOfCredential);

  const getCredentialDefaultValue = () => {
    if (isOtherTypeOfCredential) return CredentialType.Other;

    const validCredentials = healthcareCredentials.filter((credential) => credential === credentials);
    if (validCredentials.length > 0) return validCredentials[0];
  };

  const {
    formState: { errors, isValid },
    trigger,
    register,
    unregister,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      credential: getCredentialDefaultValue(),
      other_credential: isOtherTypeOfCredential && credentials !== CredentialType.Other ? credentials : '',
    },
  });
  const watcher = watch();

  useEffect(() => {
    register(ConfirmYourCredentialsVariable.Credential, {
      required: selectedOther ? false : getMessageForRequiredFields('Credential'),
      validate: (value) => (CREDENTIALS_REGEX.test(value) ? true : getMessageForInvalidFields('Credential')),
    });

    if (selectedOther) {
      register(ConfirmYourCredentialsVariable.OtherCredential, {
        required: CREDENTIALS_REQUIRED_MESSAGE,
        validate: (value) => (CREDENTIALS_REGEX.test(value) ? true : getMessageForInvalidFields('Credential')),
      });
    } else {
      unregister(ConfirmYourCredentialsVariable.OtherCredential);
    }
  }, [register, watcher, selectedOther]);

  const onSelectChange = (option: InputSelectOption) => {
    if (option.value === CredentialType.Other) setSelectedOther(true);
    if (selectedOther && option.value !== CredentialType.Other) setSelectedOther(false);

    setValue(ConfirmYourCredentialsVariable.Credential, option.value);
    trigger(ConfirmYourCredentialsVariable.Credential);
  };

  const handleContinue = () => {
    dispatch(
      setCredentials({
        credentials: selectedOther
          ? watcher[ConfirmYourCredentialsVariable.OtherCredential]
          : watcher[ConfirmYourCredentialsVariable.Credential],
        type: selectedOther ? CredentialType.Other : CredentialType.Normal,
      }),
    );
    handleNext();
  };

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="We couldn’t find that credential" subtitle="Please select the closest credential, below:">
        <InputSelect
          onChange={onSelectChange}
          placeholder="RN"
          value={watcher[ConfirmYourCredentialsVariable.Credential]}
          name={ConfirmYourCredentialsVariable.Credential}
          label={ConfirmYourCredentialsInputLabel.Credential}
          options={[
            ...healthcareCredentials.map((credential) => ({ value: credential, label: credential })),
            ...[{ value: CredentialType.Other, label: 'Other' }],
          ]}
          errorMessage={errors[ConfirmYourCredentialsVariable.Credential]?.message}
        />
        {selectedOther && (
          <Input
            onChange={(event) => {
              setValue(ConfirmYourCredentialsVariable.OtherCredential, event.target.value);
              trigger(ConfirmYourCredentialsVariable.OtherCredential);
            }}
            placeholder="Please enter your credentials"
            value={watcher[ConfirmYourCredentialsVariable.OtherCredential]}
            name={ConfirmYourCredentialsVariable.OtherCredential}
            label="Credentials"
            errorMessage={errors[ConfirmYourCredentialsVariable.OtherCredential]?.message}
            onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          />
        )}
        <Button disabled={!isValid} onClick={handleContinue}>
          Get Special Rate
        </Button>
      </FormContainer>
    </div>
  );
};

export default ConfirmYourCredentials;
