export enum AboutYouVariable {
  FirstName = 'first_name',
  LastName = 'last_name',
  Credentials = 'credentials',
  NoCredentials = 'no_credentials',
}

export enum ConfirmYourCredentialsVariable {
  Credential = 'credential',
  CredentialType = 'credential_type',
  OtherCredential = 'other_credential',
}

export enum YourBirthDateVariable {
  DateOfBirth = 'birth_date',
}

export enum YourSSN4Variable {
  SSN4 = 'SSN4',
}

export enum YourDetailsVariable {
  AddressLine1 = 'address_line_1',
  AddressLine2 = 'address_line_2',
  City = 'city',
  ZipCode = 'zip_code',
  ArgyleState = 'argyle_state',
}

export enum CreditReportVariable {
  SocialSecurityNumber = 'social_security_number',
}

export enum BankAccountVariable {
  AccountHolder = 'accountHolder',
  BankName = 'bankName',
  RoutingNumber = 'routingNumber',
  AccountNumber = 'accountNumber',
}

export enum YourContactVariable {
  Email = 'email',
  PhoneNumber = 'phone_number',
}

export enum YourIncomeVariable {
  CreditScoreRange = 'credit_score_range',
  TotalAnnualIncome = 'total_annual_income',
  StartOfEmployment = 'start_of_employment',
  EmployerName = 'employer_name',
  NotEmployed = 'not_employed',
  JobTitle = 'job_title',
  PayFrequency = 'pay_frequency',
}
