import React, { FC, useEffect, useState } from 'react';

import clsx from 'clsx';

import useScreenSize from 'hooks/useScreenSize';

import { formatDate, formatToYearMonth, getYearsFromNow, addMonths, formatYearMonthToDate } from 'utils/dateUtils';
import InputError from 'components/InputError';
import InputSelect, { InputSelectOption } from 'components/InputSelect/InputSelect';

import styles from './MonthYearPicker.module.scss';

interface MonthYearPickerProps {
  name: string;
  onChange: (value?: string) => void;
  label?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  maxDate?: Date;
  minDate?: Date;
}

const MONTH_OPTIONS = Array.from({ length: 12 }, (_, i) => ({
  label: new Date(0, i).toLocaleString('en', { month: 'long' }),
  value: i.toString(),
})) as InputSelectOption[];

const getYearOptions = (minDate: Date, maxDate: Date) =>
  Array.from({ length: maxDate.getFullYear() - minDate.getFullYear() + 1 }, (_, i) => {
    const year = minDate.getFullYear() + i;
    return { label: year.toString(), value: year.toString() };
  }) as InputSelectOption[];

const MonthYearPicker: FC<MonthYearPickerProps> = ({
  name,
  onChange,
  label,
  value,
  disabled,
  className,
  errorMessage,
  minDate = getYearsFromNow(-20),
  maxDate = getYearsFromNow(0),
}) => {
  const { isMobile } = useScreenSize();
  const defaultValues = value ? value.split('/') : [];
  const defaultMonth = defaultValues[0] && Number(defaultValues[0]) - 1;
  const defaultYear = defaultValues[2] && defaultValues[2];

  const [month, setMonth] = useState<string | undefined>(defaultMonth?.toString() ?? undefined);
  const [year, setYear] = useState<string | undefined>(defaultYear);
  const [isMonthInputSupported, setIsMonthInputSupported] = useState(true);

  // Add one extra month to maxDate to prevent issues with the native date picker on Android.
  // However, validation ensures that users cannot select a date beyond the actual maxDate.
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const maxDateConverted = isAndroid ? formatToYearMonth(addMonths(new Date(maxDate), 1)) : formatToYearMonth(maxDate);

  useEffect(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'month');
    setIsMonthInputSupported(input.type === 'month');
  }, []);

  useEffect(() => {
    if (!isMonthInputSupported) {
      if (month && year) {
        const date = new Date(Number(year), Number(month));
        onChange(formatDate(date));
      }
    }
  }, [month, year]);

  const handleChangeNativePicker = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const inputValue = event.target.value;
    const [nativePickerYear] = inputValue.split('-');

    if (/^0/.test(nativePickerYear) || !nativePickerYear) return onChange(undefined);

    const convertedDate = formatYearMonthToDate(inputValue);
    onChange(formatDate(convertedDate));
  };

  return (
    <div className={clsx(styles.inputContainer, className)}>
      {label && <p className={styles.label}>{label}</p>}
      {isMonthInputSupported ? (
        <input
          name={name}
          className={clsx(
            styles.input,
            !value && styles.customInputContainer,
            disabled && styles.disabled,
            disabled && isIOS && styles.disableNativeMobile,
          )}
          type="month"
          min={formatToYearMonth(minDate)}
          max={maxDateConverted}
          value={value ? formatToYearMonth(new Date(value)) : undefined}
          onChange={handleChangeNativePicker}
          data-placeholder={isMobile ? 'MM/YYYY' : undefined}
          disabled={disabled}
        />
      ) : (
        <div className={styles.monthYearContainer}>
          <div className={styles.monthPicker}>
            <InputSelect
              disabled={disabled}
              options={MONTH_OPTIONS}
              onChange={(option) => setMonth(option.value)}
              placeholder="Month"
              name={`${name}-month`}
              value={month}
            />
          </div>
          <div className={styles.yearPicker}>
            <InputSelect
              disabled={disabled}
              options={getYearOptions(minDate, maxDate).reverse()}
              onChange={(option) => setYear(option.value)}
              placeholder="Year"
              name={`${name}-year`}
              value={year}
            />
          </div>
        </div>
      )}
      {errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default MonthYearPicker;
