import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import Button from 'components/Button';
import MonthYearPicker from 'components/MonthYearPicker';
import { formatDate } from 'utils/dateUtils';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import { updateStudentLoanAssistanceData } from 'thunks';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const NonProfitStartDate = ({ flags, navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId, nonProfitStartDate: defaultNonProfitStartDate } = useSelector(getStudentLoanData);
  const [nonProfitStartDate, setNonProfitStartDate] = useState<string | null | undefined>(defaultNonProfitStartDate);
  const [isLoading, setIsLoading] = useState(false);
  const handleNext = async () => {
    setIsLoading(true);
    analytics.track('Student Loan Non-Profit Start Date Selected', { date: nonProfitStartDate });
    await dispatchWithUnwrap(
      updateStudentLoanAssistanceData({
        applicationId: applicationId!,
        nonProfitStartDate: new Date(nonProfitStartDate!).toISOString(),
        forProfit: false,
      }),
    );
    setIsLoading(false);
    onNext();
  };

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <MonthYearPicker
              name="nonProfitStartDate"
              value={nonProfitStartDate ? formatDate(new Date(nonProfitStartDate)) : undefined}
              label="When did you start working full time in non-profits or government (not specific to your current employer)?"
              onChange={(date) => setNonProfitStartDate(date)}
            />
            <p className={styles.hint}>An estimate is fine here. We’ll verify this later.</p>

            <Button
              disabled={Boolean(!nonProfitStartDate)}
              onClick={handleNext}
              isLoading={isLoading}
              className={styles.button}
            >
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default NonProfitStartDate;
