import React, { useEffect } from 'react';

import Input from 'components/Input';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import FormContainer from 'components/LoanForm/FormContainer';
import { healthcareCredentials, CREDENTIALS_REQUIRED_MESSAGE } from 'components/LoanForm/ConfirmYourCredentials';
import UserSessionWarning from 'components/Common/UserSessionWarning/UserSessionWarning';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { getFullName, setFirstAndLastName, setCredentials, CredentialType } from 'handlers/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { YourNameResult } from 'enums/FlowNextResults';
import Button from 'components/Button';
import { getYourNameData } from 'selectors/yourName';
import { useLocation } from 'react-router-dom';

import styles from './YourName.module.scss';

export enum YourNameInputLabel {
  FirstName = 'First Name',
  LastName = 'Last Name',
  Credentials = 'Credentials (e.g., MD, RN, PA)',
  NoCredentials = 'I do not have a clinical credential',
}

export interface YourNameState {
  isSessionExpired?: boolean;
  isFromConfirmDetails?: boolean;
}

export const CREDENTIALS_REGEX = /^[A-Za-z ,()/-]{2,}$/;

const YourName = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const location = useLocation();
  const isSessionExpired = (location.state as YourNameState)?.isSessionExpired;
  const isFromConfirmDetails = (location.state as YourNameState)?.isFromConfirmDetails;

  const dispatch = useDispatch();

  const { first_name: firstName, last_name: lastName, credentials, credential_type: credentialType } = useSelector(
    getYourNameData,
  );

  const { isFirstName, isLastName } = useSelector((state: RootState) => state.userPayrollData);

  const {
    formState: { errors, isValid },
    trigger,
    register,
    unregister,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      credentials,
      no_credentials: credentialType === CredentialType.NoCredential,
    },
  });
  const watcher = watch();

  const normalizeCredential = (credential: string) => credential.toLowerCase().replace(/[^a-zA-Z]/g, '');
  const credentialMap = new Map(healthcareCredentials.map((original) => [normalizeCredential(original), original]));

  const isValidCredential = (credential: string) => credentialMap.has(credential);

  const validateCredentials = () => {
    if (watcher[AboutYouVariable.NoCredentials]) return [];

    const userCredentials = (watcher[AboutYouVariable.Credentials] ?? '').toLowerCase();

    const listCredentials = userCredentials
      .split(',')
      .map((credential) => credential.trim().replace(/[^a-zA-Z]/g, ''))
      .filter((credential) => credential !== '');

    const validCredentials = Array.from(
      new Set(listCredentials.filter(isValidCredential).map((credential) => credentialMap.get(credential)!)),
    );

    return validCredentials;
  };

  const getCredentialType = (
    noCredentialsSelected: boolean,
    haveCredentialsChanged: boolean,
    credentialsToStore?: string,
  ) => {
    if (noCredentialsSelected) return CredentialType.NoCredential;
    if (credentialsToStore === CredentialType.Other) return CredentialType.Other;

    // Re-enable the credentials list if the user previously selected "other" and then decides to update their credentials again.
    if (haveCredentialsChanged) return CredentialType.Normal;
  };

  const handleContinue = () => {
    const validatedCredentials = validateCredentials();
    const validCredentials = validatedCredentials.join(', ');
    const credentialsToStore =
      validatedCredentials.length > 0 ? validCredentials : watcher[AboutYouVariable.Credentials];
    const haveCredentialsChanged = credentials !== credentialsToStore;
    const noCredentialsSelected = watcher[AboutYouVariable.NoCredentials];

    const newCredentialType = getCredentialType(noCredentialsSelected, haveCredentialsChanged, credentialsToStore);

    dispatch(
      setCredentials({
        credentials: credentialsToStore !== '' ? credentialsToStore : undefined,
        type: newCredentialType ?? credentialType,
      }),
    );

    dispatch(
      setFirstAndLastName({
        [AboutYouVariable.FirstName]: watcher[AboutYouVariable.FirstName],
        [AboutYouVariable.LastName]: watcher[AboutYouVariable.LastName],
      }),
    );

    analytics.identify({
      firstName: watcher[AboutYouVariable.FirstName],
      lastName: watcher[AboutYouVariable.LastName],
      name: getFullName(watcher[AboutYouVariable.FirstName], watcher[AboutYouVariable.LastName]),
    });

    if (validCredentials.length === 0 && !noCredentialsSelected)
      return handleNext(YourNameResult.ConfirmYourCredentials);

    handleNext();
  };

  useEffect(() => {
    register(AboutYouVariable.FirstName, {
      required: getMessageForRequiredFields(YourNameInputLabel.FirstName),
    });
    register(AboutYouVariable.LastName, {
      required: getMessageForRequiredFields(YourNameInputLabel.LastName),
    });
    if (!watch(AboutYouVariable.NoCredentials)) {
      register(AboutYouVariable.Credentials, {
        required: CREDENTIALS_REQUIRED_MESSAGE,
        validate: (value) => (CREDENTIALS_REGEX.test(value) ? true : getMessageForInvalidFields('Credentials')),
      });
    } else {
      if (errors[AboutYouVariable.Credentials]) clearErrors(AboutYouVariable.Credentials);
      unregister(AboutYouVariable.Credentials);
    }
    register(AboutYouVariable.NoCredentials);
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value.trim());
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value);
    trigger(event.target.name as AboutYouVariable);
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} {...(isFromConfirmDetails && { title: 'Update Details' })} />
      <FormContainer title="What's your name?" subtitle="Nice to meet you!">
        {isSessionExpired && <UserSessionWarning message="Sorry, your session is expired. Please reapply" />}

        <div className={styles.inputs}>
          <Input
            label={YourNameInputLabel.FirstName}
            placeholder="First Name"
            errorMessage={errors[AboutYouVariable.FirstName]?.message}
            className={styles.formInput}
            name={AboutYouVariable.FirstName}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[AboutYouVariable.FirstName]}
            disabled={isFirstName}
            data-neuro-label="firstName"
            autoComplete="given-name"
            onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
            autoFocus
          />
          <Input
            label={YourNameInputLabel.LastName}
            placeholder="Last Name"
            errorMessage={errors[AboutYouVariable.LastName]?.message}
            className={styles.formInput}
            name={AboutYouVariable.LastName}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[AboutYouVariable.LastName]}
            disabled={isLastName}
            data-neuro-label="lastName"
            autoComplete="family-name"
            onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          />
          {!watch(AboutYouVariable.NoCredentials) && (
            <Input
              label={YourNameInputLabel.Credentials}
              placeholder="RN, MD, etc."
              errorMessage={errors[AboutYouVariable.Credentials]?.message}
              className={styles.formInput}
              name={AboutYouVariable.Credentials}
              onBlur={onBlur}
              onChange={onChange}
              value={watcher[AboutYouVariable.Credentials]}
              disabled={watcher[AboutYouVariable.NoCredentials]}
              autoComplete="credentials"
              onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
            />
          )}
          <CheckboxSmall
            label={YourNameInputLabel.NoCredentials}
            name={AboutYouVariable.NoCredentials}
            checked={watcher[AboutYouVariable.NoCredentials]}
            onChange={(event) => {
              setValue(AboutYouVariable.NoCredentials, event.target.checked);
              setValue(AboutYouVariable.Credentials, '');
              trigger([AboutYouVariable.Credentials, AboutYouVariable.NoCredentials]);
            }}
          />
        </div>

        <Button disabled={!isValid} onClick={handleContinue} className={styles.button}>
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default YourName;
