import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getLoanOffer, updateLoanOffer } from 'thunks';
import { LoanOfferProgress } from 'api/LoanOfferApi';

interface LoanOffer {
  isLoading: boolean;
  isCompleted: boolean;
  isError: boolean;
  progress?: LoanOfferProgress;
  response: LoanOfferResponse;
}
export interface LoanOfferData {
  application_status?: ApplicationStatusName | undefined;
  application_id?: string | undefined;
  borrower_id?: string | undefined;
  credit_passed?: boolean | undefined;
}
export interface LoanOfferResponse {
  passed: boolean | undefined;
  data: LoanOfferData;
}

const initialState: LoanOffer = {
  response: {
    passed: undefined,
    data: {},
  },
  progress: undefined,
  isLoading: false,
  isCompleted: false,
  isError: false,
};

const loanOffer = createSlice({
  name: 'loanOffer',
  initialState,
  reducers: {
    setOfferProgress: (state: LoanOffer, { payload }: PayloadAction<LoanOfferProgress>) => {
      state.progress = payload;
    },
  },
  extraReducers: (builder) => {
    const handlePending = (state: LoanOffer) => {
      state.isLoading = true;
      state.isCompleted = false;
      state.isError = false;
    };

    const handleFulfilled = (state: LoanOffer, payload: LoanOfferResponse) => {
      state.isLoading = false;
      state.isCompleted = true;
      state.response = payload;
    };

    const handleRejected = (state: LoanOffer) => {
      state.isLoading = false;
      state.isCompleted = true;
      state.isError = true;
    };

    builder.addCase(getLoanOffer.pending, handlePending);
    builder.addCase(getLoanOffer.fulfilled, (state, { payload }) => handleFulfilled(state, payload));
    builder.addCase(getLoanOffer.rejected, handleRejected);

    builder.addCase(updateLoanOffer.pending, handlePending);
    builder.addCase(updateLoanOffer.fulfilled, (state, { payload }) => handleFulfilled(state, payload));
    builder.addCase(updateLoanOffer.rejected, handleRejected);
  },
});

export const { setOfferProgress } = loanOffer.actions;

export default loanOffer.reducer;
