import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonType } from 'components/Button/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { getApplicationData as selectApplicationData } from 'selectors/getApplicationData';
import { FlowComponentType } from 'routes/types';
import useLoanOfferProcessor from 'hooks/useLoanOfferProcessor';
import { updateLoanOffer } from 'thunks';
import { ApplyingResult } from 'enums/FlowNextResults';
import UserSessionWarning from 'components/Common/UserSessionWarning/UserSessionWarning';

import styles from './UnfreezeCreditScore.module.scss';

const EXPERIAN_URL = 'https://www.experian.com/help/credit-freeze/';
const TRANSUNION_URL = 'https://www.transunion.com/credit-freeze';

const providers = [
  {
    name: 'TransUnion',
    url: TRANSUNION_URL,
  },
  {
    name: 'Experian',
    url: EXPERIAN_URL,
  },
];

const UnfreezeCreditScore = ({ handleNext }: FlowComponentType): JSX.Element => {
  const [rerunFailed, setRerunFailed] = useState(false);

  const { isLoading } = useSelector(getLoanOffer);
  const { application } = useSelector(selectApplicationData);

  const { processLoanOffer } = useLoanOfferProcessor({
    handleNext,
  });

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleRerunOffer = async () => {
    setRerunFailed(false);

    const runLoanOffer = () => dispatchWithUnwrap(updateLoanOffer({ applicationId: application!.id }));

    const res = await processLoanOffer(runLoanOffer);

    if (!res || res.data.credit_passed) {
      handleNext(ApplyingResult.Done);
    } else {
      setRerunFailed(true);
    }
  };

  return (
    <FormContainer title="Your Credit Seems Frozen" subtitle="Please unfreeze your credit to continue:">
      {providers.map((provider, index) => (
        <React.Fragment key={provider.name}>
          <p>
            {index + 1}. Remove your {provider.name} freeze
          </p>
          <Button key={provider.name} type={ButtonType.Transparent} onClick={() => window.open(provider.url, '_blank')}>
            👉 Manage My Freeze
          </Button>
        </React.Fragment>
      ))}
      {rerunFailed && (
        <UserSessionWarning
          message="It looks like your credit score is still frozen. Please check it and try again"
          showContactUsOption
        />
      )}
      <Button className={styles.primaryButton} isLoading={isLoading} onClick={handleRerunOffer}>
        Rerun My Offer
      </Button>
    </FormContainer>
  );
};

export default UnfreezeCreditScore;
