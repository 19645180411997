import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';
import { AboutYouVariable, ConfirmYourCredentialsVariable } from 'enums/LoanFormVariables';
import { GetUserDataResponse } from 'api/UserDataApi';
import { ConversionType, trackConversion } from 'utils/analytics';

export interface AboutYouResponse {
  [AboutYouVariable.FirstName]: string;
  [AboutYouVariable.LastName]: string;
}

export enum CredentialType {
  Normal = 'normal',
  Other = 'other',
  NoCredential = 'no_credential',
}

interface AboutYouStateInterface {
  [AboutYouVariable.FirstName]: string;
  [AboutYouVariable.LastName]: string;
  [AboutYouVariable.Credentials]?: string;
  [ConfirmYourCredentialsVariable.CredentialType]: CredentialType;
  fullName: string;
}

export const initialState: AboutYouStateInterface = {
  [AboutYouVariable.FirstName]: '',
  [AboutYouVariable.LastName]: '',
  [ConfirmYourCredentialsVariable.CredentialType]: CredentialType.Normal,
  fullName: '',
};

const aboutYou = createSlice({
  name: 'aboutYou',
  initialState,
  reducers: {
    setFirstAndLastName: (
      state: AboutYouStateInterface,
      { payload }: PayloadAction<{ [AboutYouVariable.FirstName]: string; [AboutYouVariable.LastName]: string }>,
    ) => {
      const firstName = payload[AboutYouVariable.FirstName];
      const lastName = payload[AboutYouVariable.LastName];
      const fullName = getFullName(firstName, lastName);

      if (lastName && !state[AboutYouVariable.LastName]) {
        trackConversion(ConversionType.CollectedName);
      }

      state[AboutYouVariable.FirstName] = firstName;
      state[AboutYouVariable.LastName] = lastName;
      state.fullName = fullName;
    },

    setFullName: (state: AboutYouStateInterface, { payload }: PayloadAction<{ fullName: string }>) => {
      const { fullName } = payload;
      const { firstName, lastName } = splitFullName(fullName);

      if (lastName && !state[AboutYouVariable.LastName]) {
        trackConversion(ConversionType.CollectedName);
      }

      state[AboutYouVariable.FirstName] = firstName;
      state[AboutYouVariable.LastName] = lastName;
      state.fullName = fullName;
    },

    setCredentials: (
      state: AboutYouStateInterface,
      { payload }: PayloadAction<{ credentials?: string; type?: CredentialType }>,
    ) => {
      state[AboutYouVariable.Credentials] = payload.credentials;
      if (payload.type) state[ConfirmYourCredentialsVariable.CredentialType] = payload.type;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      if (payload.isLinkSupported) {
        state[AboutYouVariable.FirstName] = !state[AboutYouVariable.FirstName]
          ? payload.aboutYou[AboutYouVariable.FirstName]
          : state[AboutYouVariable.FirstName];

        state[AboutYouVariable.LastName] = !state[AboutYouVariable.LastName]
          ? payload.aboutYou[AboutYouVariable.LastName]
          : state[AboutYouVariable.LastName];
      }
    });
  },
});

export const { setFirstAndLastName, setFullName, setCredentials } = aboutYou.actions;

export default aboutYou.reducer;

export const getFullName = (firstName: string, lastName: string): string => `${firstName} ${lastName}`;

export const splitFullName = (fullName: string | null): { firstName: string; lastName: string } => {
  const [firstName, ...lastParts] = fullName?.split(' ') || [];
  const lastName = lastParts.join(' ');
  return { firstName, lastName };
};
